import { Injectable } from '@angular/core';
import {
  AccountQueryResult,
  AccountData,
  AccountAmortizationData,
  AccountStateUpdateDto,
  AccountOfferData,
  AccountWithRenewalData,
  ProductCode,
  AccountOnboarding,
  AccountBreakdown,
  AccountAverage,
  LoanStatusUpdateDto,
  LoanOtherInfoUpdateDto,
  AccountModificationQueryResult,
  AccountModification,
  AccountModificationPreview,
  AccountModificationRequestDto,
  AccountStatementsRequestData,
  AccountStatementsData
} from './account.model';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import {
  LoanInfoDto,
  ResourceService,
  QueryParams,
  LoanStatus,
  PaymentFrequency,
  AccountHintData,
  AccountHintParams,
  AccountQueryParams
} from 'common';
import { CustomerData } from '../customer/customer.model';
import { AppSettings } from '../../app.settings';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  prevAccountListUrl: string;
  onCurrentLoanChanged: Subject<AccountData>;
  obsCurrentLoan: Observable<AccountData>;

  constructor(private http: HttpClient,
    private settings: AppSettings,
    private resourceService: ResourceService) {
    this.onCurrentLoanChanged = new Subject<AccountData>();
    this.obsCurrentLoan = this.onCurrentLoanChanged.asObservable();
  }

  calculateTotalNumberOfPayments(term: number, paymentFrequency: PaymentFrequency): number {
    switch (paymentFrequency) {
      case PaymentFrequency.BiWeekly:
        return Math.round(term * 26 / 12);

      case PaymentFrequency.Monthly:
        return term;

      default:
        return Math.round(term * 52 / 12);
    }
  }

  calculatePaymentAmount(
    principalBalance: number,
    termInterestRate: number,
    numberOfPaymentsLeft: number,
    termFees: number
  ) {
    const p = principalBalance;
    const i = termInterestRate;
    const n = numberOfPaymentsLeft;
    const f = termFees;

    const ip1 = 1 + i;
    const ip1mN = Math.pow(ip1, -n);
    const d = i / (1 - ip1mN);

    let payment = p * d;

    if (f > 0)
      payment += f;

    payment = Math.round(payment * 100) / 100;
    return payment;
  }

  calculateTermLoanAutoPayment(
    principalBalance: number,
    currentAccruedInterest: number,
    maintenanceAccruedInterest: number,
    interestRate: number,
    numberOfPaymentsLeft: number
  ) {
    const i = currentAccruedInterest - maintenanceAccruedInterest;
    const a = principalBalance * (interestRate / 100);

    return Math.ceil((principalBalance + a + i) * 100 / numberOfPaymentsLeft) / 100;
  }

  getPaymentAmount(account: AccountData, accountInfo: LoanInfoDto): number {
    if (account.productCode === ProductCode["Term Loan"])
      return this.calculateTermLoanAutoPayment(
        accountInfo.adjustedPrincipalBalance,
        account.currentState.accruedInterest,
        account.maintenanceState.accruedInterest,
        account.interestRate,
        accountInfo.totalNumberOfPayments
      );

    return this.calculatePaymentAmount(
      accountInfo.adjustedPrincipalBalance,
      accountInfo.termInterestRate,
      accountInfo.totalNumberOfPayments,
      accountInfo.termMaintenanceFee
    );
  }

  getOutstandingBalance(account: AccountData): number {
    if (account.productCode === ProductCode["Term Loan"])
      return account.currentState.principalBalance + account.currentState.accruedInterest;
    else
      return account.currentState?.principalBalance;
  }

  get(id: number): Observable<AccountData> {
    return this.http.get<AccountData>(`${this.settings.loans.url}/api/account/${id}`);
  }
  
  delete(id: number): Observable<AccountData> {
    return this.http.delete<AccountData>(`${this.settings.loans.url}/api/account/${id}`);
  }

  getWithRenewal(id: number): Observable<AccountData> {
    return this.http.get<AccountWithRenewalData>(`${this.settings.loans.url}/api/account/${id}/with-renewal`);
  }

  getByUniqueId(id: number): Observable<AccountData> {
    return this.http.get<AccountData>(`${this.settings.loans.url}/api/account/unique/${id}`);
  }

  getByLoanNumber(loanNumber: number) {
    return this.http.get<AccountData>(`${this.settings.loans.url}/api/account`, {
      params: { loanNumber }
    });
  }

  getInfo(id: number): Observable<LoanInfoDto> {
    return this.http.get<LoanInfoDto>(`${this.settings.loans.url}/api/account/info/${id}`);
  }

  getInfoByUniqueId(id: number): Observable<LoanInfoDto> {
    return this.http.get<LoanInfoDto>(`${this.settings.loans.url}/api/account/info/unique/${id}`);
  }

  query(params: AccountQueryParams): Observable<AccountQueryResult> {
    if(!params.sort) {
      params.sort = '+loanNumber';
    }

    return this.http.get<AccountQueryResult>(`${this.settings.loans.url}/api/account/query`, { params: <any>params });
  }

  queryHints(query: AccountHintParams): Observable<AccountHintData[]> {
    return this.http.get<AccountHintData[]>(`${this.settings.loans.url}/api/account/hints`, { params: <any>query });
  }

  getAmortization(loanId: number): Observable<AccountAmortizationData> {
    return this.http.get(`${this.settings.loans.url}/api/amortization/${loanId}`);
  }

  getAmortizationByUniqueId(uniqueId: string): Observable<AccountAmortizationData> {
    return this.http.get(`${this.settings.loans.url}/api/amortization/unique/${uniqueId}`);
  }

  getAmortizationCsv(loanId: number) {
    this.resourceService.download(`${this.settings.loans.url}/api/amortization/${loanId}/csv`, "upcoming-payments.csv");
  }

  getOwnersList(id: number): Observable<CustomerData[]> {
    return this.http.get<CustomerData[]>(`${this.settings.loans.url}/api/account/${id}/owners`);
  }

  getLoanStatementsGeneration(id: number): Observable<AccountStatementsData> {
    return this.http.get<AccountStatementsData>(`${this.settings.loans.url}/api/account/${id}/statements-generation`);
  }

  download(params: AccountQueryParams) {
    this.resourceService.download(`${this.settings.loans.url}/api/account/csv`, "accounts.csv", params);
  }

  updateLoanAdvisor(loanId: number, advisorId: number): Observable<any> {
    return this.http.patch(`${this.settings.loans.url}/api/account/${loanId}/advisor/${advisorId}`, null);
  }

  updateMaintenanceState(id: number, state: AccountStateUpdateDto): Observable<any> {
    return this.http.post(`${this.settings.loans.url}/api/account/${id}/state/maintenance`, state);
  }

  updateLoanOffer(id: number, offer: AccountOfferData): Observable<any> {
    return this.http.post(`${this.settings.loans.url}/api/account/${id}/economics`, offer);
  }

  updateAccountStatus(id: number, loanStatus: LoanStatusUpdateDto): Observable<any> {
    return this.http.post(`${this.settings.loans.url}/api/account/${id}/status`, loanStatus);
  }

  updateLoanOtherInfo(id: number, info: LoanOtherInfoUpdateDto): Observable<any> {
    return this.http.put(`${this.settings.loans.url}/api/account/${id}/other-info`, info);
  }

  updateLoanStatementsGenearation(loanId: number, request: AccountStatementsRequestData): Observable<any> {
    return this.http.patch(`${this.settings.loans.url}/api/account/${loanId}/statements-generation`, request);
  }

  addNote(id: number, note: string): Observable<any> {
    return this.http.post(`${this.settings.loans.url}/api/account/${id}/notes`, { note: note });
  }

  onboardingInfo(): Observable<AccountOnboarding> {
    return this.http.get(`${this.settings.loans.url}/api/report/onboarding`);
  }

  onboardingByAdvisorInfo(params: AccountQueryParams): Observable<any> {
    return this.http.get(`${this.settings.loans.url}/api/report/onboarding-by-advisor`, { params: <any>params });
  }

  breakdownInfo(): Observable<AccountBreakdown> {
    return this.http.get(`${this.settings.loans.url}/api/report/breakdown`);
  }

  averagesInfo(): Observable<AccountAverage[]> {
    return this.http.get<AccountAverage[]>(`${this.settings.loans.url}/api/report/averages`);
  }

  reAmortize(id: number): Observable<any> {
    return this.http.post(`${this.settings.loans.url}/api/loan-modification/${id}/re-amortize`, null);
  }

  getAccountModificationList(id: number, params: QueryParams): Observable<AccountModificationQueryResult> {
    return this.http.get<AccountModificationQueryResult>(`${this.settings.loans.url}/api/loan-modification/${id}`, { params: <any>params });
  }

  createAccountModification(id: number, request: AccountModificationRequestDto): Observable<any> {
    return this.http.post<AccountModificationQueryResult>(`${this.settings.loans.url}/api/loan-modification/${id}`, request);
  }

  updateAccountModification(id: number, request: AccountModificationRequestDto): Observable<any> {
    return this.http.put<AccountModification>(`${this.settings.loans.url}/api/loan-modification/${id}`, request);
  }

  // eslint-disable-next-line max-len
  previewModification(id: number, modification: AccountModification, reAmortize: boolean, status?: LoanStatus, freezeForDays?: number, repaymentTerm?: number): Observable<AccountModificationPreview> {
    const request: AccountModificationRequestDto = {
      modification: modification,
      status: status,
      freezeForDays: freezeForDays,
      repaymentTerm: repaymentTerm,
      reAmortize: reAmortize
    };
    return this.http.post<AccountModificationPreview>(`${this.settings.loans.url}/api/loan-modification/${id}/preview`, request);
  }

  isNPLLoan(status: LoanStatus): boolean {
    return _.includes([LoanStatus.Delinquent, LoanStatus.Workout, LoanStatus.PaymentRelief, LoanStatus.WriteOff], status);
  }

}
